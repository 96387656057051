<template>
  <div class="content-tab-users" ref="content">
    <div class="box-content">
      <b-row class="mt-1">
        <ProgressUpload />
      </b-row>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="year"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <b-form-checkbox
            v-show="switchMonth == false"
            v-model="switchMonth"
            @change="handleChangeSwitch('switchWeek')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Mois </b>
          </b-form-checkbox>
          <b-form-checkbox
            v-show="switchWeek == false"
            v-model="switchWeek"
            @change="handleChangeSwitch('switchMonth')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Semaine </b>
          </b-form-checkbox>
        </div>
        <div class="box-label-champ" v-if="switchWeek == true">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>
          <SelectComponent
            :options="computedListWeekMin"
            :value="semaine"
            label="text"
            champName="semaine"
            filterName="semaine"
            :change="handleChangeSemaine"
            :track-by="'text'"
            :max-heigh="130"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style "
          />
        </div>
        <div class="box-label-champ mr-2" v-if="switchMonth == true">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="getListMonth"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>
        <div class="box-label-champ">
          <b-button
            v-if="ComputedButtonValidation === true && cantValid($options.name)"
            class="button-succes-style ml-2"
            size="sm"
            variant="success"
            @click="handleValider"
          >
            <span
              v-if="
                ComputedgetMissigSemaineRapport &&
                  ComputedgetMissigSemaineRapport.length == 0
              "
              >Validé
              <font-awesome-icon icon="check" class="ml-1" />
            </span>
            <span v-else> à Valider</span>
          </b-button>
        </div>
        <div class="reload" v-if="getloadingEdit" @click="ReloadData">
          Une nouvelle version de donnée disponible . cliquez ici pour
          actualiser la page
        </div>
        <div v-if="getChargement" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <div
            class="warning-week mt-1"
            v-if="
              ComputedgetMissigSemaineRapport != null &&
                ComputedgetMissigSemaineRapport.length &&
                switchWeek == false
            "
          >
            Semaines à valider {{ ComputedgetMissigSemaineRapport }}
          </div>
          <b-button
            v-if="
              ComputedButtonValidation === true &&
                this.getValidation === true &&
                cantValid($options.name)
            "
            class="button-error-style"
            size="sm"
            variant="danger"
            @click="handleDeValider"
            >Dévalider
          </b-button>
          <font-awesome-icon
            v-if="
              this.semaine &&
                this.semaine.value &&
                switchWeek == true &&
                cantDelete($options.name)
            "
            icon="trash"
            class="trash-reset-style"
            @click.prevent="ResetDataInBase()"
          />
          <b-button
            v-if="cantExport($options.name)"
            size="sm"
            variant="light"
            class="button-default-style ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
      <div class="body-box-rapport">
        <div class="tabs-menu-style">
          <img
            src="../assets/Icon ionic-md-arrow-dropright-circle.png"
            class="flesh-icon"
            :class="{ 'flesh-icon-rigth': showTabs == false }"
            @click="showTabs = !showTabs"
          />
          <div
            v-for="item in computedTabsList"
            :key="item.title"
            @click="handleChangeTabs(item)"
          >
            <div
              class="box-tabs"
              :class="{ 'Active-tabs-style': selectedTabs == item.title }"
            >
              <div class="icon">
                <img :src="item.img" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <hr class="ligne" />
          </div>
        </div>
        <div
          class="tabs-body-style"
          :class="{
            'display-tabs': showTabs == false,
            'zoom-box': selectedTabs == 'Doublons dossier' && zoom == true
          }"
        >
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Répartition types'"
          >
            <div class="title-tab-item">Répartition types</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <Charts
                :label="['Tous', 'invalides', 'valides']"
                :data="computedgetValiditeData"
                :datasets="datasetsValidite"
                :width="217"
                :height="150"
                :type="type"
                chartType="Type"
                @selected="handleChangeType"
                :changeType="computedChengeTypeLegend"
                class="display"
                :changeYearOrWeek="getChangePourcentage"
              />
              <div class="surface-box" v-if="getTotalSurface">
                <div class="title">Total surface</div>
                <div class="valueBox">{{ getTotalSurface }} m²</div>
              </div>
            </div>
          </div>
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Répartition Anomalies'"
          >
            <div class="title-tab-item">Répartition Anomalies</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <Charts
                class="display"
                :label="computedgetAnomalieLabel"
                :data="computedgetAnomalieData"
                :datasets="datasetAomalie"
                chartType="Anomalie"
                :width="217"
                :height="150"
                :type="anomalie"
                @selected="selected"
                :changeYearOrWeek="getChangePourcentage"
              />
            </div>
          </div>
          <div class="tab-item-style" v-show="selectedTabs == 'Filtres'">
            <div class="title-tab-item">Filtres</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <label class="label-tab-style ">Colonne</label>
              <SelectComponent
                :options="ComputedgetAllHeader"
                :value="header"
                label="label"
                champName="colone"
                :change="searchHeaders"
                :track-by="'label'"
                placeholder="Rechercher"
                :searchable="true"
                :max-height="130"
                :showLabels="false"
                classToUse="select-vue-component-style select-vue-component-style-raduis-all mb-3"
              />
              <SearchInput
                :value="searchNum"
                @changeSearchValue="changeSearchValue"
                label="Rechercher dans la colonne"
                classToUse="serachLocale"
              />
            </div>
          </div>
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Intervalles validés'"
          >
            <div class="title-tab-item">Intervalles validés</div>
            <hr class="ligne" />
            <div
              class="body-tab-item"
              v-if="
                getIntervalSemaineValider && getIntervalSemaineValider.length
              "
            >
              <span
                v-for="(item, index) in getIntervalSemaineValider"
                :key="index"
              >
                <b-badge class="mr-2 badge-style">{{ item }}</b-badge>
              </span>
            </div>
          </div>
          <div class="tab-item-style" v-show="selectedTabs == 'Filiale'">
            <div class="title-tab-item">Filiale</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <div class="filter-filiale">
                <div class="filiale-header">
                  <div
                    @click="handleChangeFiliale(null)"
                    class="filiale-icon-selected"
                    :title="
                      this.filiale && this.filiale.name ? this.filiale.name : ''
                    "
                    :style="computedImageFiliale(this.filiale)"
                  ></div>
                  <div class="filiale-white-box"></div>
                </div>
                <div class="filiale-body" id="filiale-body">
                  <div
                    class="filiale-box"
                    v-for="filiale in computedGetFiliales"
                    :key="filiale.id"
                  >
                    <div
                      class="filiale-box-icon"
                      :title="filiale.name"
                      :style="computedImageFiliale(filiale)"
                      @click="handleChangeFiliale(filiale)"
                    ></div>
                    <font-awesome-icon
                      v-if="!filiale.loading && filiale.download"
                      icon="download"
                      class="filiale-icon-downoad"
                      :class="{
                        disabledIcon: selectedFiliale != null
                      }"
                      @click.prevent="exportFilialeRapportWithId(filiale)"
                      :style="positionIconDownload(filiale)"
                    />
                    <font-awesome-icon
                      v-if="filiale.loading"
                      icon="spinner"
                      spin
                      class="filiale-icon-downoad-loader"
                      :style="positionIconDownload(filiale)"
                    />
                    <div
                      class="filiale-no-anomalie"
                      v-if="
                        !filiale.loadingAnomalie &&
                          filiale.anomalies.length == 0
                      "
                    >
                      aucune anomalie
                    </div>
                    <div class="filiale-box-body" id="filiale-box-body">
                      <div
                        v-if="filiale.loadingAnomalie"
                        class="three-dots-loading "
                      >
                        Chargement en cours
                      </div>

                      <div
                        v-else
                        class="filiale-anomalie"
                        v-for="anomalie in filiale.anomalies"
                        :key="anomalie.id"
                        @click="
                          HandleChangeAnomalieFiliale({
                            anomalie: anomalie,
                            filiale: filiale
                          })
                        "
                      >
                        <div class="filiale-anomalie-title">
                          {{ anomalie.name | AnomalieFormat }} :
                        </div>
                        <div>{{ anomalie.pourcentage }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Doublons dossier'"
          >
            <div class="title-tab-item">
              Doublons dossiers
              <font-awesome-icon
                icon="compress"
                class="zoom-icon-style"
                @click.prevent="zoom = !zoom"
              />
            </div>

            <hr class="ligne" />
            <div class="body-tab-item">
              <NumerouDoublent
                :semaine="ComputedSemaineDoublent"
                :annee="ComputedAnneeDoublent"
                type="ISO"
                :change="ComputedChangeDoublent"
              />
            </div>
          </div>
        </div>
        <div
          class="table-rapport-style"
          :class="{ 'width-table-rapport': showTabs == false }"
        >
          <b-table
            v-if="computedRows && !getError"
            responsive
            show-empty
            id="my-table"
            class="custom-table-style custom-table-rapport-style "
            :items="computedRows"
            :fields="fields"
            sticky-header
            bordered
            hover
            head-variant="light"
            empty-text="Il n'y a aucun enregistrement à afficher"
            :tbody-tr-class="rowClass"
          >
            <template v-slot:head(anomalie)>
              <button
                ref="anomalie"
                class="btn btn-anomalie"
                :class="{
                  desactive: ShowALL == false,
                  active: ShowALL == true,
                  thColorSearch: getClass('anomalie') === true
                }"
                @click="ShowALL ? hideAllDetails() : showAllDetails()"
              >
                <font-awesome-icon icon="bug" />
              </button>
            </template>
            <template v-slot:head()="data">
              <div
                :ref="data.field.key"
                :tabindex="-1"
                :class="{ thColorSearch: getClass(data.field.key) === true }"
              >
                {{ data.field.label }}
              </div>
            </template>

            <template v-slot:cell(anomalie)="data">
              <button
                class="btn btn-anomalie"
                :class="{
                  desactive: data.detailsShowing == false,
                  active: data.detailsShowing == true
                }"
                @click="data.toggleDetails"
              >
                <font-awesome-icon icon="bug" />
              </button>
            </template>
            <template v-slot:cell(numero_dossier)="data">
              {{ data.item.numero_dossier }}
            </template>
            <template v-slot:cell(type_de_projet)="data">
              {{ data.item.type_de_projet }}
            </template>
            <template v-slot:cell(semaine)="data">
              {{ data.item.semaine }}
            </template>
            <template v-slot:cell(nom1)="data">
              <div :ref="'nom1' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="nom1"
                  :item="data.item"
                  :year="year"
                  :value="data.item.nom1"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(prenom1)="data">
              <div :ref="'prenom1' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="prenom1"
                  :item="data.item"
                  :year="year"
                  :value="data.item.prenom1"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(email)="data">
              <div :ref="'email' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="email"
                  :item="data.item"
                  :year="year"
                  :value="data.item.email"
                  type="email"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(adresse)="data">
              <div :ref="'adresse' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="adresse"
                  :item="data.item"
                  :year="year"
                  :value="data.item.adresse"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(code_postal)="data">
              <div :ref="'code_postal' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="code_postal"
                  :item="data.item"
                  :year="year"
                  :value="data.item.code_postal"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(departement)="data">
              <div :ref="'departement' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="departement"
                  :item="data.item"
                  :year="year"
                  :value="data.item.departement"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>

            <template v-slot:cell(a_2_euro)="data">
              <div :ref="'a_2_euro' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="a_2_euro"
                  :item="data.item"
                  :year="year"
                  :value="data.item.a_2_euro"
                  type="number"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(a_3_euro)="data">
              <div :ref="'a_3_euro' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="a_3_euro"
                  :item="data.item"
                  :year="year"
                  :value="data.item.a_3_euro"
                  type="number"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(ville)="data">
              <div :ref="'ville' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="ville"
                  :item="data.item"
                  :year="year"
                  :value="data.item.ville"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(mobile)="data">
              <div :ref="'mobile' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="mobile"
                  :item="data.item"
                  :year="year"
                  :value="data.item.mobile"
                  type="tel"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(fixe)="data">
              <div :ref="'fixe' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="fixe"
                  :item="data.item"
                  :year="year"
                  :value="data.item.fixe"
                  type="tel"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(etape)="data">
              <div :ref="'etape' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="etape"
                  :item="data.item"
                  :year="year"
                  :value="data.item.etape"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(categorie)="data">
              <div :ref="'categorie' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="categorie"
                  :item="data.item"
                  :year="year"
                  :value="data.item.categorie"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(statut)="data">
              <div :ref="'statut' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="statut"
                  :item="data.item"
                  :year="year"
                  :value="data.item.statut"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(organisme)="data">
              <div :ref="'organisme' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="organisme"
                  :item="data.item"
                  :year="year"
                  :value="data.item.organisme"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(regie)="data">
              <div :ref="'regie' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="regie"
                  editableType="select"
                  :optionsSelect="computedgetAllRegie"
                  :item="data.item"
                  :year="year"
                  :value="data.item.regie"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(commercial_regie)="data">
              <div :ref="'commercial_regie' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="commercial_regie"
                  editableType="select"
                  :optionsSelect="computedgetAllSousRegie"
                  :item="data.item"
                  :year="year"
                  :value="data.item.commercial_regie"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(installeur_rge)="data">
              <div :ref="'installeur_rge' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="installeur_rge"
                  :item="data.item"
                  :year="year"
                  :value="data.item.installeur_rge"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(previsiteur)="data">
              <div :ref="'previsiteur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="previsiteur"
                  editableType="select"
                  :optionsSelect="computedgetListPrevisiteur"
                  :item="data.item"
                  :year="year"
                  :value="data.item.previsiteur"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(confirmateur)="data">
              <div :ref="'confirmateur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="confirmateur"
                  editableType="select"
                  :optionsSelect="computedgetListCommercialSedentaire"
                  :item="data.item"
                  :year="year"
                  :value="data.item.confirmateur"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(administrateur)="data">
              <div :ref="'administrateur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="administrateur"
                  :item="data.item"
                  :year="year"
                  :value="data.item.administrateur"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(agent_commercial_terrain)="data">
              <div
                :ref="'agent_commercial_terrain' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="agent_commercial_terrain"
                  editableType="select"
                  :optionsSelect="computedgetListCommercialTerrain"
                  :item="data.item"
                  :year="year"
                  :value="data.item.agent_commercial_terrain"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(pose_date)="data">
              <div :ref="'pose_date' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="pose_date"
                  :item="data.item"
                  :year="year"
                  :value="data.item.pose_date"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  type="datetime"
                  editableType="datetime"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(pose_semaine)="data">
              <div :ref="'pose_semaine' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="pose_semaine"
                  :item="data.item"
                  :year="year"
                  :value="data.item.pose_semaine"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(poseur)="data">
              <div :ref="'poseur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="poseur"
                  editableType="select"
                  :optionsSelect="computedgetListPoseur"
                  :item="data.item"
                  :year="year"
                  :value="data.item.poseur"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(coef_ratio_prime)="data">
              <div :ref="'coef_ratio_prime' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="coef_ratio_prime"
                  :item="data.item"
                  :year="year"
                  :value="data.item.coef_ratio_prime"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(montant_101)="data">
              <div :ref="'montant_101' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_101"
                  :item="data.item"
                  :year="year"
                  :value="data.item.montant_101"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(montant_102)="data">
              <div :ref="'montant_102' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_102"
                  :item="data.item"
                  :year="year"
                  :value="data.item.montant_102"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(montant_103)="data">
              <div :ref="'montant_103' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_103"
                  :item="data.item"
                  :year="year"
                  :value="data.item.montant_103"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(montant_total_ht)="data">
              <div :ref="'montant_total_ht' + data.index" :tabindex="-1">
                {{ data.item.montant_total_ht }}
              </div>
            </template>
            <template v-slot:cell(montant_total_ttc)="data">
              <div :ref="'montant_total_ttc' + data.index" :tabindex="-1">
                {{ data.item.montant_total_ttc }}
              </div>
            </template>
            <template v-slot:cell(surface_101)="data">
              <div :ref="'surface_101' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_101"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_101"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102)="data">
              <div :ref="'surface_102' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_102"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_102"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103)="data">
              <div :ref="'surface_103' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_103"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_101_cp_deroule)="data">
              <div :ref="'surface_101_cp_deroule' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_101_cp_deroule"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_101_cp_deroule"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_101_cp_souffle)="data">
              <div :ref="'surface_101_cp_souffle' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_101_cp_souffle"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_101_cp_souffle"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_101_rampant)="data">
              <div :ref="'surface_101_rampant' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_101_rampant"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_101_rampant"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102_murs)="data">
              <div :ref="'surface_102_murs' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_102_murs"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_102_murs"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102_pignons)="data">
              <div :ref="'surface_102_pignons' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_102_pignons"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_102_pignons"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102_murs_iti)="data">
              <div :ref="'surface_102_murs_iti' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_102_murs_iti"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_102_murs_iti"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102_murs_ite)="data">
              <div :ref="'surface_102_murs_ite' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_102_murs_ite"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_102_murs_ite"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_sous_sol)="data">
              <div
                :ref="'surface_103_plafond_sous_sol' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_103_plafond_sous_sol"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103_plafond_sous_sol"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_garage)="data">
              <div
                :ref="'surface_103_plafond_garage' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_103_plafond_garage"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103_plafond_garage"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_cave)="data">
              <div
                :ref="'surface_103_plafond_cave' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_103_plafond_cave"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103_plafond_cave"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_autres)="data">
              <div
                :ref="'surface_103_plafond_autres' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_103_plafond_autres"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103_plafond_autres"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_plafonds)="data">
              <div :ref="'surface_103_plafonds' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="surface_103_plafonds"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103_plafonds"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_vide_sanitaire)="data">
              <div
                :ref="'surface_103_vide_sanitaire' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_103_vide_sanitaire"
                  :item="data.item"
                  :year="year"
                  :value="data.item.surface_103_vide_sanitaire"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>

            <template v-slot:cell(total_surface_a_isoler)="data">
              <div :ref="'total_surface_a_isoler' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="total_surface_a_isoler"
                  :item="data.item"
                  :year="year"
                  :value="data.item.total_surface_a_isoler"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(cumac_101)="data">
              <div :ref="'cumac_101' + data.index" :tabindex="-1">
                {{ data.item.cumac_101 }}
              </div>
            </template>
            <template v-slot:cell(cumac_102)="data">
              <div :ref="'cumac_102' + data.index" :tabindex="-1">
                {{ data.item.cumac_102 }}
              </div>
            </template>
            <template v-slot:cell(cumac_103)="data">
              <div :ref="'cumac_103' + data.index" :tabindex="-1">
                {{ data.item.cumac_103 }}
              </div>
            </template>
            <template v-slot:cell(kwh_cumac)="data">
              <div :ref="'kwh_cumac' + data.index" :tabindex="-1">
                {{ data.item.kwh_cumac }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc_101)="data">
              <div :ref="'total_prime_ttc_101' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc_101 }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc_102)="data">
              <div :ref="'total_prime_ttc_102' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc_102 }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc_103)="data">
              <div :ref="'total_prime_ttc_103' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc_103 }}
              </div>
            </template>
            <template v-slot:cell(total_commission_tcc)="data">
              <div :ref="'total_commission_tcc' + data.index" :tabindex="-1">
                {{ data.item.total_commission_tcc }}
              </div>
            </template>
            <template v-slot:cell(precarite)="data">
              <div :ref="'precarite' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="precarite"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: 'Classique' },
                    { full_name: 'Grande' },
                    { full_name: 'Simple' }
                  ]"
                  :item="data.item"
                  :year="year"
                  :value="data.item.precarite"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(type_chauffage)="data">
              <div :ref="'type_chauffage' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="type_chauffage"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: 'Electrique' },
                    { full_name: 'Combustible' }
                  ]"
                  :item="data.item"
                  :year="year"
                  :value="data.item.type_chauffage"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(personnes_au_foyer)="data">
              <div :ref="'personnes_au_foyer' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="personnes_au_foyer"
                  :item="data.item"
                  :year="year"
                  :value="data.item.personnes_au_foyer"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(numero_facture)="data">
              <div :ref="'numero_facture' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="numero_facture"
                  :item="data.item"
                  :year="year"
                  :value="data.item.numero_facture"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(numero_lot)="data">
              <div :ref="'numero_lot' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="numero_lot"
                  :item="data.item"
                  :year="year"
                  :value="data.item.numero_lot"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(num_depot)="data">
              <div :ref="'num_depot' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="num_depot"
                  :item="data.item"
                  :year="year"
                  :value="data.item.num_depot"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(date_devis)="data">
              <div :ref="'date_devis' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_devis"
                  :item="data.item"
                  :year="year"
                  :value="data.item.date_devis"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  type="datetime"
                  editableType="datetime"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(date_facture)="data">
              <div :ref="'date_facture' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_facture"
                  :item="data.item"
                  :year="year"
                  :value="data.item.date_facture"
                  :semaine="semaine"
                  :typefilter="type"
                  type="datetime"
                  editableType="datetime"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(coposeurs)="data">
              <div :ref="'coposeurs' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="coposeurs"
                  editableType="select"
                  :optionsSelect="computedgetListPoseur"
                  :item="data.item"
                  :year="year"
                  :value="data.item.coposeurs"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(revenu_fiscal_ref)="data">
              <div :ref="'revenu_fiscal_ref' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="revenu_fiscal_ref"
                  :item="data.item"
                  :year="year"
                  :value="data.item.revenu_fiscal_ref"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(date_depot_dossier)="data">
              <div :ref="'date_depot_dossier' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_depot_dossier"
                  :item="data.item"
                  :year="year"
                  :value="data.item.date_depot_dossier"
                  :semaine="semaine"
                  :typefilter="type"
                  type="datetime"
                  editableType="datetime"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(societe_poseur)="data">
              <div :ref="'societe_poseur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="societe_poseur"
                  :item="data.item"
                  :year="year"
                  :value="data.item.societe_poseur"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  type="text"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(responsable_zone)="data">
              <div :ref="'responsable_zone' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="responsable_zone"
                  :item="data.item"
                  :year="year"
                  :value="data.item.responsable_zone"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(depot_stockage)="data">
              <div :ref="'depot_stockage' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="depot_stockage"
                  :item="data.item"
                  :year="year"
                  :value="data.item.depot_stockage"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(source)="data">
              <div :ref="'source' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="source"
                  :item="data.item"
                  :year="year"
                  :value="data.item.source"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(pipedrive_lead_id)="data">
              <div :ref="'pipedrive_lead_id' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="pipedrive_lead_id"
                  :item="data.item"
                  :year="year"
                  :value="data.item.pipedrive_lead_id"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(date_creation_fiche)="data">
              <div :ref="'date_creation_fiche' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_creation_fiche"
                  :item="data.item"
                  :year="year"
                  :value="data.item.date_creation_fiche"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(type_lead)="data">
              <div :ref="'type_lead' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="type_lead"
                  :item="data.item"
                  :year="year"
                  :value="data.item.type_lead"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(commentaire)="data">
              <div :ref="'commentaire' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="commentaire"
                  :item="data.item"
                  :year="year"
                  :value="data.item.commentaire"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(zone_climatique)="data">
              <div :ref="'zone_climatique' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="zone_climatique"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: 'H1' },
                    { full_name: 'H2' },
                    { full_name: 'H3' }
                  ]"
                  :item="data.item"
                  :year="year"
                  :value="data.item.zone_climatique"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(montant_operation)="data">
              <div :ref="'montant_operation' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_operation"
                  :item="data.item"
                  :year="year"
                  :value="data.item.montant_operation"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(created_at)="data">
              <div :ref="'created_at' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="created_at"
                  :item="data.item"
                  :year="year"
                  :value="data.item.created_at"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(updated_at)="data">
              <div :ref="'updated_at' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="updated_at"
                  :item="data.item"
                  :year="year"
                  :value="data.item.updated_at"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(previsite_date)="data">
              <div :ref="'previsite_date' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="previsite_date"
                  :item="data.item"
                  :year="year"
                  :value="data.item.previsite_date"
                  :semaine="semaine"
                  type="datetime"
                  editableType="datetime"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                />
              </div>
            </template>
            <template v-slot:cell(montant_prime_CEE)="data"
              ><div :ref="'montant_prime_CEE' + data.index" :tabindex="-1">
                {{ data.item.montant_prime_CEE }}
              </div></template
            >
            <template v-slot:cell(couleur)="data"
              ><div :ref="'couleur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="couleur"
                  editableType="select"
                  :optionsSelect="computedgetListCouleur"
                  :item="data.item"
                  :year="year"
                  :value="data.item.couleur"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                /></div
            ></template>
            <template v-slot:cell(montant_MPR)="data"
              ><div :ref="'montant_MPR' + data.index" :tabindex="-1">
                {{ data.item.montant_MPR }}
              </div></template
            >
            <template v-slot:cell(reste_a_charge)="data"
              ><div :ref="'reste_a_charge' + data.index" :tabindex="-1">
                {{ data.item.reste_a_charge }}
              </div></template
            >
            <template v-slot:cell(dossier_MPR)="data"
              ><div :ref="'dossier_MPR' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  :devalider="getDeValidation"
                  champName="dossier_MPR"
                  editableType="select"
                  :optionsSelect="computedgetListMPR"
                  :item="data.item"
                  :year="year"
                  :value="data.item.dossier_MPR"
                  type="text"
                  :semaine="semaine"
                  :typefilter="type"
                  :anomalies="anomalie"
                  :anom="getAnomalieData"
                  :typeData="getValiditeData"
                  :updateFunction="updateRow"
                  :filiale="filiale"
                  :searchNum="searchNum"
                  :month="filterMonth"
                  :switchMonth="switchMonth"
                /></div
            ></template>
            <template v-slot:cell(dossier_referance)="data"
              ><div :ref="'dossier_referance' + data.index" :tabindex="-1">
                {{ data.item.dossier_referance }}
              </div></template
            >
            <template #row-details="data">
              <b-card class="cardAnomalie" v-if="data.item.anomalies">
                <p><strong>Anomalie </strong></p>
                <b-row
                  class="mb-2"
                  v-for="anomalie in data.item.anomalies"
                  :key="anomalie.id"
                >
                  <div
                    class="anomalie"
                    @click="focus(anomalie.name + data.index)"
                  >
                    <b>{{ anomalie.name | MessageFormat }} </b>
                  </div>
                  <div class="anomalie">{{ anomalie.message }}</div>
                </b-row>
              </b-card>
              <b-card class="classAlert" v-if="data.item.alerts">
                <p><strong>Alerts</strong></p>
                <b-row
                  class="mb-2"
                  v-for="alert in data.item.alerts"
                  :key="alert.id"
                >
                  <div class="anomalie" @click="focus(alert.name + data.index)">
                    <b>{{ alert.name | MessageFormat }} </b>
                  </div>
                  <div class="anomalie">{{ alert.message }}</div>
                </b-row>
              </b-card>
            </template>
          </b-table>
          <div v-if="this.computedRows && !getError" class=" footer-style">
            <b-pagination
              v-model="page"
              :total-rows="getTotalRows"
              :per-page="perPage"
              aria-controls="my-table"
              pills
              align="center"
              size="sm"
              @change="pagination"
              class="pagination-style"
            ></b-pagination>
            <div class="per-page-element-style">
              <div class="box-label-champ">
                <div class="label-box-style">
                  <span class="title-tabel">Eléments par page</span>
                </div>
              </div>
              <b-form-select
                v-model="perPage"
                :options="perPageList"
                @change="changePerPage"
                class="b-form-select-new-style bg-select"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>

      <b-row
        align-h="center"
        class="mt-2  justify-content-between"
        v-if="getError"
      >
        <b-modal
          id="bv-modal-erreur"
          ref="bv-modal-erreur"
          hide-footer
          hide-header
          centered
        >
          <div class="d-block">
            <h5>{{ getError }}</h5>
          </div>
          <div class="actionModel">
            <b-button
              class="button-cancel-style"
              @click="$bvModal.hide('bv-modal-erreur')"
              >OK</b-button
            >
          </div>
        </b-modal>
      </b-row>
    </div>
    <canvas
      id="canvas"
      height="320"
      width="550"
      class="graph-filiale"
      style="display:none"
    ></canvas>
    <loader v-if="this.getLoading" />
  </div>
</template>
<script
  type="text/javascript"
  src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.2/Chart.min.js"
></script>
<script>
import moment from 'moment';
import loader from './Loader';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Analyse-et-validation',
  data() {
    return {
      switchMonth: false,
      switchWeek: true,
      filterMonth: 1,
      zoom: false,
      showTabs: true,
      selectedTabs: 'Répartition types',
      TabsList: [
        {
          title: 'Répartition types',
          img: 'image/Icon-ionic-ios-pie.png'
        },
        {
          title: 'Répartition Anomalies',
          img: 'image/Icon-ionic-ios-pie.png'
        },
        {
          title: 'Filtres',
          img: 'image/Icon-material-filter-list.png'
        },
        {
          title: 'Intervalles validés',
          img: 'image/Icon-material-space-bar.png'
        },
        {
          title: 'Filiale',
          img: 'image/Icon-filiale.png'
        },
        {
          title: 'Doublons dossier',
          img: 'image/icon-folder.png'
        }
      ],
      selectedFiliale: null,
      myLine: null,
      dataurl: '',
      messageValidation: null,
      header: null,
      searchNum: null,
      semaine: null,
      changeYearOrWeek: false,
      changeTypeLegende: false,
      datasetsValidite: {
        position: 'left',
        title: 'Répartition types',
        backgroundColor: ['#4D4BAC', '#DC3C3C', '#699D86']
      },
      datasetAomalie: {
        position: 'right',
        title: 'Répartition anomalies',
        backgroundColor: [
          '#000000',
          '#660033',
          '#CC0000',
          '#FF6666',
          '#CC0066',
          '#FFCCE5',
          '#FFCCFF',
          '#FFCCCC',
          '#F5DEB3',
          '#eca3a9',
          '#E0E0E0',
          '#A0A0A0'
        ]
      },
      year: null,
      type: null,
      anomalie: [],
      filiale: null,
      ListYear: [],
      ListWeekMin: [],
      ShowALL: false,
      page: 1,
      perPage: 50,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      fields: [
        {
          key: 'anomalie',
          label: 'Anomalie',
          thClass: 'th-anomalie',
          tdClass: 'col-anomalie'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          thClass: 'colhead-num-dossier',
          tdClass: 'col-num-dossier'
        },
        { key: 'type_de_projet', label: 'Type de projet' },
        { key: 'prenom1', label: 'Prenom' },
        { key: 'nom1', label: 'Nom' },
        { key: 'email', label: 'Email' },
        { key: 'semaine', label: 'Semaine' },
        { key: 'adresse', label: 'Adresse' },
        { key: 'code_postal', label: 'Code Postal' },
        { key: 'departement', label: 'Departement' },
        { key: 'ville', label: 'Ville' },
        { key: 'mobile', label: 'Mobile' },
        { key: 'fixe', label: 'Fixe' },
        { key: 'etape', label: 'Etape' },
        { key: 'categorie', label: 'Categorie' },
        { key: 'statut', label: 'Statut' },
        { key: 'organisme', label: 'Organisme' },
        { key: 'regie', label: 'Regie' },
        { key: 'commercial_regie', label: 'Campagne' },
        { key: 'installeur_rge', label: 'Installeur rge' },
        {
          key: 'previsiteur',
          label: 'Pré-visiteur'
        },
        { key: 'confirmateur', label: 'Confirmateur' },
        { key: 'agent_commercial_terrain', label: 'Agent commercial terrain' },
        { key: 'administrateur', label: 'Agent technique' },
        { key: 'pose_date', label: 'Pose date' },
        { key: 'pose_semaine', label: 'Pose semaine' },
        { key: 'poseur', label: 'Poseur' },
        { key: 'coef_ratio_prime', label: 'Coef ratio prime' },
        { key: 'a_2_euro', label: 'A 2€' },
        { key: 'a_3_euro', label: 'A 3€' },
        { key: 'montant_101', label: 'Montant 101' },
        { key: 'montant_102', label: 'Montant 102' },
        { key: 'montant_103', label: 'Montant 103' },
        { key: 'montant_total_ht', label: 'Montant total ht' },
        {
          key: 'montant_total_ttc',
          label: 'Montant total TTC'
        },
        {
          key: 'surface_101_cp_deroule',
          label: 'Surface 101 cp deroule'
        },
        {
          key: 'surface_101_cp_souffle',
          label: 'Surface 101 cp souffle'
        },
        {
          key: 'surface_101_rampant',
          label: 'Surface 101 rampant'
        },
        { key: 'surface_101', label: 'Surface 101' },
        { key: 'surface_102_murs', label: 'Surface 102 murs' },
        {
          key: 'surface_102_pignons',
          label: 'Surface 102 pignons'
        },
        {
          key: 'surface_102_murs_iti',
          label: 'Surface 102 murs_iti'
        },
        {
          key: 'surface_102_murs_ite',
          label: 'Surface 102 murs ite'
        },
        { key: 'surface_102', label: 'Surface 102' },
        {
          key: 'surface_103_plafond_sous_sol',
          label: 'Surface 103 Plafond Sous-sol'
        },
        {
          key: 'surface_103_plafond_garage',
          label: 'Surface 103 Plafond Garage'
        },
        {
          key: 'surface_103_plafond_cave',
          label: 'Surface 103 Plafond Cave'
        },
        {
          key: 'surface_103_plafond_autres',
          label: 'Surface 103 Plafond autres'
        },
        {
          key: 'surface_103_plafonds',
          label: 'Surface 103 plafonds'
        },
        {
          key: 'surface_103_vide_sanitaire',
          label: 'Surface 103 vide sanitaire'
        },
        { key: 'surface_103', label: 'Surface 103' },
        {
          key: 'total_surface_a_isoler',
          label: 'Total surface a isoler'
        },
        { key: 'cumac_101', label: 'Cumac 101' },
        { key: 'cumac_102', label: 'Cumac 102' },
        { key: 'cumac_103', label: 'Cumac 103' },
        { key: 'kwh_cumac', label: 'Kwh cumac' },
        { key: 'total_prime_ttc_101', label: 'total prime TTC 101' },
        { key: 'total_prime_ttc_102', label: 'total prime TTC 102' },
        { key: 'total_prime_ttc_103', label: 'total prime TTC 103' },
        { key: 'total_commission_tcc', label: 'total commission TTC' },
        { key: 'precarite', label: 'Precarite' },
        { key: 'type_chauffage', label: 'Type chauffage' },
        { key: 'zone_climatique', label: 'Zone climatique' },

        {
          key: 'personnes_au_foyer',
          label: 'Personnes au foyer'
        },
        { key: 'numero_facture', label: 'Numero facture' },
        { key: 'numero_lot', label: 'Numero lot' },
        { key: 'num_depot', label: 'Num depot' },
        { key: 'date_devis', label: 'Date devis' },
        { key: 'date_facture', label: 'Date facture' },
        { key: 'coposeurs', label: 'Coposeurs' },
        {
          key: 'revenu_fiscal_ref',
          label: 'Revenu fiscal ref'
        },
        {
          key: 'date_depot_dossier',
          label: 'Date depot dossier'
        },
        { key: 'societe_poseur', label: 'Societe poseur' },
        { key: 'responsable_zone', label: 'Responsable zone' },
        { key: 'depot_stockage', label: 'Depot stockage' },
        { key: 'source', label: 'Source' },
        { key: 'pipedrive_lead_id', label: 'Pipedrive lead id' },

        {
          key: 'date_creation_fiche',
          label: 'Date creation fiche'
        },
        { key: 'type_lead', label: 'Type lead' },
        { key: 'commentaire', label: 'Commentaire' },
        {
          key: 'montant_operation',
          label: 'Montant operation'
        },
        { key: 'created_at', label: 'Created at' },
        { key: 'updated_at', label: 'Updated at' },
        { key: 'previsite_date', label: 'Prévisite date' },
        { key: 'montant_prime_CEE', label: 'Montant PRIME CEE' },
        { key: 'couleur', label: 'Couleur' },
        { key: 'montant_MPR', label: 'Montant MPR' },
        { key: 'reste_a_charge', label: 'Reste à charge' },
        { key: 'dossier_MPR', label: 'Dossier MPR' },
        { key: 'dossier_referance', label: 'Dossier réference' }
      ],
      changeDoublent: true
    };
  },
  methods: {
    ...mapActions([
      'fetchAnalyse',
      'updateRow',
      'exportFile',
      'fetchProgressAnalyse',
      'fetchAnalyseChart',
      'validStep',
      'fetchEmployeParType',
      'fetchAllRegieForAdmin',
      'fastSearch',
      'fetchSemaineValider',
      'ReloadChartData',
      'getAllFiliales',
      'getAnomalieForFiliale',
      'getRapportFiliale',
      'getPdfRapportFiliale',
      'fetchAllSousRegieForAdmin',
      'ResetDataForWeek',
      'resetMissingRapport',
      'getMissingSemainesRapport'
    ]),
    ...mapMutations(['SET_LOADING_EDIT', 'SET_DEVALIDATION_PARAMS']),
    FilterGetMissingSemainesRapport() {
      this.getMissingSemainesRapport({
        month: this.filterMonth,
        year: this.year,
        switchMonth: this.switchMonth,
        semaine: this.semaine
      });
    },
    fetchAnalyseRapport() {
      this.fetchAnalyse({
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        type: this.type,
        anomalies: this.anomalie,
        anom: this.getAnomalieData,
        typeData: this.getValiditeData,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        filiale_id: this.filiale,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    handleChangeMonth() {
      this.fetchAnalyseRapport();
      this.FilterGetMissingSemainesRapport();
    },
    handleChangeSwitch(item) {
      if (item == 'switchMonth') {
        this.switchMonth = false;
        // this.resetMissingRapport();
      } else {
        this.switchWeek = false;
        // this.FilterGetMissingSemainesRapport();
      }
      this.FilterGetMissingSemainesRapport();
      this.setLocalStorageRapport();
      this.fetchAnalyseRapport();
    },
    filtergetAnomalieForFiliale() {
      this.getAnomalieForFiliale({
        semaine: this.semaine,
        annee: this.year,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    handleChangeTabs(tab) {
      if (tab.title == 'Filiale') {
        this.filtergetAnomalieForFiliale();
      }
      this.selectedTabs = tab.title;
    },
    FilterfetchAnalyseChart() {
      this.fetchAnalyseChart({
        changeWeekYear: true,
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        type: this.type,
        anomalies: this.anomalie,
        anom: this.getAnomalieData,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        filiale_id: this.filiale,

        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    handleChangeFiliale(filiale) {
      if (filiale !== this.filiale) {
        this.filiale = filiale;
        this.anomalie = [];
        this.type = null;
        this.setLocalStorageRapport();
        this.FilterfetchAnalyseChart();
      }
    },
    HandleChangeAnomalieFiliale({ anomalie, filiale }) {
      var change = false;
      if (this.type != 'invalides') {
        this.type = 'invalides';
        this.changeTypeLegende = !this.changeTypeLegende;
        change = true;
      }
      if (
        this.filiale === null ||
        (this.filiale && this.filiale.id !== filiale.id)
      ) {
        this.filiale = filiale;
        change = true;
      }
      if (this.anomalie.find(item => item === anomalie.name) === undefined) {
        this.anomalie.push(anomalie.name);
        change = true;
      }
      this.setLocalStorageRapport();
      this.page = 1;
      sessionStorage.setItem('page-rapport', this.page);
      if (change) {
        this.FilterfetchAnalyseChart();
      }
    },
    async done() {
      var url = this.myLine.toBase64Image();
      this.dataurl = url;
      await this.getPdfRapportFiliale({
        semaine: this.semaine,
        annee: this.year,
        filiale: this.selectedFiliale,
        url: this.dataurl,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      this.selectedFiliale.loading = false;
      this.selectedFiliale = null;
    },
    sortTable() {
      function compare(a, b) {
        if (a.pourcentage < b.pourcentage) return 1;
        if (a.pourcentage > b.pourcentage) return -1;
        return 0;
      }

      return this.getFilialeSelectedRapport.anomalies_per_depots.sort(compare);
    },
    async exportFilialeRapportWithId(filiale) {
      this.selectedFiliale = filiale;
      filiale.loading = true;
      const responce = await this.getRapportFiliale({
        semaine: this.semaine,
        annee: this.year,
        filiale: filiale,

        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      if (responce) {
        this.sortTable();
        const plugin = {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            var my_gradient = ctx.createLinearGradient(chart.width, 0, 0, 0);
            my_gradient.addColorStop(0, '#cecece');
            my_gradient.addColorStop(0.5, 'white');
            my_gradient.addColorStop(1, '#cecece');
            ctx.fillStyle = my_gradient;
            ctx.fillRect(0, 0, chart.width, chart.height);
          }
        };
        var lineChartData = {
          labels: this.computedGetLabelFiliale,
          datasets: [
            {
              backgroundColor: '#5684d6d9',
              borderColor: 'transparent',
              borderWidth: 1,
              data: this.computedGetDataFiliale
            }
          ]
        };
        var options = {
          title: {
            display: true,
            text: 'Nombre de Dossier ou il y a des erreurs',
            fontColor: 'black',
            fontStyle: 'italic',
            fontSize: 20,
            padding: 25,
            weight: 800
          },
          layout: {
            padding: {
              left: 15,
              right: 15,
              top: 0,
              bottom: 15
            }
          },
          legend: { display: false },
          plugins: {
            labels: {
              render: 'value',
              fontColor: 'black',
              fontSize: 20,
              weight: 800
            }
          },
          scales: {
            xAxes: [
              {
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                barThickness: 35,
                maxBarThickness: 35,
                ticks: {
                  min: 0,
                  stepSize: 1,
                  color: 'green'
                },
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.1)',
                  drawBorder: false
                },
                ticks: {
                  display: false,
                  max: Math.max(...this.computedGetDataFiliale) + 1,
                  beginAtZero: true
                }
              }
            ],
            y: {
              display: false
            }
          },
          animation: {
            onComplete: this.done
          }
        };
        this.myLine = new Chart(
          document.getElementById('canvas').getContext('2d'),
          {
            data: lineChartData,
            type: 'bar',
            options: options,
            plugins: [plugin]
          }
        );
      } else {
        this.selectedFiliale = null;
        filiale.loading = false;
      }
    },
    ChangeMessageValidation(payload) {
      this.messageValidation = payload;
    },
    handleValider() {
      var message =
        this.switchMonth == true
          ? 'la mois' + this.filterMonth
          : 'la semaine' + this.semaine.value;
      this.$confirm('Êtes-vous sur de vouloir valider  ' + message)
        .then(() => {
          this.validStep({
            semaine: this.semaine,
            year: this.year,
            type: 'ISO',
            month: this.filterMonth,
            switchMonth: this.switchMonth
          }).then(() => {
            this.$nextTick(() => {
              this.SET_DEVALIDATION_PARAMS(false);
            });
          });
        })
        .catch(error => {});
    },
    handleDeValider() {
      var message =
        this.switchMonth == true
          ? 'la mois' + this.filterMonth
          : 'la semaine' + this.semaine.value;
      this.$confirm('Êtes-vous sur de vouloir dévalider ' + message)
        .then(() => {
          this.SET_DEVALIDATION_PARAMS(true);
        })
        .catch(error => {});
    },
    ReloadData() {
      this.SET_LOADING_EDIT(false);
      this.fetchAnalyse({
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        type: this.type,
        anomalies: this.anomalie,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        filiale_id: this.filiale,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      this.FilterGetMissingSemainesRapport();
    },
    selected(item) {
      if (this.type != 'invalides') {
        this.type = 'invalides';
        this.changeTypeLegende = !this.changeTypeLegende;
      }
      if (this.anomalie.find(anomalie => anomalie === item) === undefined) {
        this.anomalie.push(item);
      } else {
        this.anomalie = this.anomalie.filter(iteam => iteam != item);
      }
      this.setLocalStorageRapport();
      this.page = 1;
      sessionStorage.setItem('page-rapport', this.page);
      this.fetchAnalyseRapport();
    },
    handleChangeType(item) {
      if (item == 'Tous') {
        this.type = null;
      } else {
        this.type = item;
      }
      this.setLocalStorageRapport();
      this.page = 1;
      sessionStorage.setItem('page-rapport', this.page);
      this.FilterfetchAnalyseChart();
    },
    focus(key) {
      this.$refs[key].focus({ preventScroll: false });
    },
    getClass(payload) {
      if (this.header && this.header.key && this.header.key === payload) {
        return true;
      } else {
        return false;
      }
    },
    searchHeaders(payload) {
      this.header = payload.value;
      if (this.header && this.header.key && this.$refs[this.header.key]) {
        this.$refs[this.header.key].focus({ preventScroll: false });
      }
      this.setLocalStorageRapport();
    },
    async handleChangeYear() {
      this.SET_DEVALIDATION_PARAMS(false);
      this.page = 1;
      sessionStorage.setItem('page-rapport', this.page);
      this.type = null;
      this.anomalie = [];
      var totalWeek = moment({ year: this.year }).isoWeeksInYear();
      this.ListWeekMin = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeekMin.push({ value: week, text: week });
      }

      if (
        this.semaine.value > this.ListWeekMin[this.ListWeekMin.length - 1].value
      ) {
        this.semaine.value = this.ListWeekMin[
          this.ListWeekMin.length - 1
        ].value;
      }
      this.setLocalStorageRapport();
      const response = await this.fetchAnalyse({
        changeWeekYear: true,
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        type: this.type,
        anomalies: this.anomalie,
        anom: this.getAnomalieData,
        typeData: this.getValiditeData,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        filiale_id: this.filiale,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      this.changeDoublent = !this.changeDoublent;
      this.fetchSemaineValider({ year: this.year });
      if (response && this.getError == null) {
        this.ReloadChartData();
        this.fetchSemaineValider({ year: this.year });
        // if (this.switchMonth == true) {
        //   this.FilterGetMissingSemainesRapport();
        // } else {
        //   this.resetMissingRapport();
        // }
        this.FilterGetMissingSemainesRapport();
        const responcefiliale = await this.getAllFiliales();
        if (responcefiliale && this.selectedTabs == 'Filiale') {
          this.filtergetAnomalieForFiliale();
        }
      }
      if (this.getError != null) {
        this.$refs['bv-modal-erreur'].show();
      }
    },

    async handleChangeSemaine(payload) {
      if (
        this.semaine !== payload.value &&
        payload.value &&
        payload.value.value &&
        this.semaine.value !== payload.value.value
      ) {
        this.SET_DEVALIDATION_PARAMS(false);
        this.semaine = payload.value;
        this.page = 1;
        sessionStorage.setItem('page-rapport', this.page);
        this.type = null;
        this.anomalie = [];
        this.setLocalStorageRapport();
        const response = await this.fetchAnalyse({
          changeWeekYear: true,
          page: this.page,
          per_page: this.perPage,
          semaine: this.semaine,
          type: this.type,
          anomalies: this.anomalie,
          anom: this.getAnomalieData,
          typeData: this.getValiditeData,
          annee: this.year,
          name: this.searchNum,
          champ: this.header,
          filiale_id: this.filiale,
          month: this.filterMonth,
          switchMonth: this.switchMonth
        });
        this.changeDoublent = !this.changeDoublent;
        this.fetchSemaineValider({ year: this.year });
        if (response && this.getError == null) {
          this.ReloadChartData();
          // this.resetMissingRapport();
          this.FilterGetMissingSemainesRapport();
          const responcefiliale = await this.getAllFiliales();
          if (responcefiliale && this.selectedTabs == 'Filiale') {
            this.filtergetAnomalieForFiliale();
          }
        }
        if (this.getError != null) {
          this.$refs['bv-modal-erreur'].show();
        }
      }
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-rapport', this.page);
      this.fetchAnalyseRapport();
      this.ShowALL = false;
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-rapport', this.page);
      this.fetchAnalyseRapport();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.anomalies) return 'ligneAnomalie';
      else return 'ligneAlert';
    },
    async exportFiles() {
      await this.exportFile({
        semaine: this.semaine,
        type: this.type,
        anomalies: this.anomalie,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        filiale_id: this.filiale,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    showAllDetails() {
      this.computedRows.forEach(item => {
        this.$set(item, '_showDetails', true);
      });
      this.ShowALL = !this.ShowALL;
    },
    hideAllDetails() {
      this.computedRows.forEach(item => {
        this.$set(item, '_showDetails', false);
      });
      this.ShowALL = !this.ShowALL;
    },
    changeSearchValue(e) {
      this.searchNum = e;
      this.fastSearch({
        page: this.page,
        per_page: this.perPage,
        type: this.type,
        semaine: this.semaine,
        annee: this.year,
        coloneValue: this.searchNum,
        anom: this.getAnomalieData,
        typeData: this.getValiditeData,
        anomalies: this.anomalie,
        champ: this.header,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    setLocalStorageRapport() {
      localStorage.setItem(
        'Rapport-Filters',
        JSON.stringify({
          year: this.year,
          semaine: this.semaine,
          type: this.type,
          anomalie: this.anomalie,
          month: this.filterMonth,
          switchMonth: this.switchMonth
        })
      );
    },
    ResetDataInBase() {
      this.$confirm(
        'Êtes-vous sur de vouloir supprimer  rapport , les salaires et les factures pour la semaine ' +
          this.semaine.value
      )
        .then(() => {
          this.ResetDataForWeek({
            year: this.year,
            semaine: this.semaine
          }).then(() => {});
        })
        .catch(error => {});
    }
  },
  filters: {
    MessageFormat: value => {
      switch (value) {
        case 'prenom1':
          return 'prenom';
        case 'first_name':
          return 'nom';
        case 'numero_dossier':
          return 'numero de dossier';
        case 'num_depot':
          return 'numero depot';
        case 'previsiteur':
          return 'previsiteur at';
        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    },
    AnomalieFormat: value => {
      switch (value) {
        case 'previsiteur':
          return 'Prévisiteur ';
        case 'depot_stockage':
          return 'Depot ';
        case 'surface_101':
          return 'Total surface 101 est incorrect';
        case 'surface_102':
          return 'Total surface 102 est incorrect';
        case 'surface_103':
          return 'Total surface 103 est incorrect';
        case 'total_surface_a_isoler':
          return 'Total surface a isoler est incorrect';
        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    }
  },
  computed: {
    ...mapGetters([
      'getData',
      'getLoading',
      'getTotalRows',
      'getValiditeData',
      'getAnomalieData',
      'getError',
      'getStatusForRefrechData',
      'getloadingEdit',
      'getChargement',
      'getStatu',
      'getValidation',
      'getListCommercialSedentaire',
      'getListCommercialTerrain',
      'getListPoseur',
      'getListPrevisiteur',
      'getAllRegie',
      'getTotalSurface',
      'getInterval',
      'getIntervalSemaineValider',
      'getDeValidation',
      'getProgressValidation',
      'getChangePourcentage',
      'getFiliales',
      'getLoadingExpotFilialeRapport',
      'getFilialeSelectedRapport',
      'getAllSousRegie',
      'getDossierDoublent',
      'getMissigSemaineRapport',
      'cantUpdate',
      'cantExport',
      'cantValid',
      'cantDelete',
      'getListMonth'
    ]),
    ComputedEditable() {
      return this.cantUpdate(this.$options.name);
    },
    computedGetLabelFiliale() {
      if (
        this.getFilialeSelectedRapport &&
        this.getFilialeSelectedRapport.anomalies_per_depots
      ) {
        return this.getFilialeSelectedRapport.anomalies_per_depots.map(e => {
          return e.name;
        });
      }
      return [];
    },
    computedGetDataFiliale() {
      if (
        this.getFilialeSelectedRapport &&
        this.getFilialeSelectedRapport.anomalies_per_depots
      ) {
        return this.getFilialeSelectedRapport.anomalies_per_depots.map(e => {
          return e.pourcentage;
        });
      }
      return [];
    },
    computedGetFiliales() {
      if (this.getFiliales) {
        return this.getFiliales.filter(row => row.download === true);
      } else {
        return [];
      }
    },
    computedImageFiliale() {
      return function(data) {
        if (data && data.image) {
          return { 'background-image': 'url(' + `${data.image}` + ')' };
        } else {
          return { 'background-color': '#dcd9d9' };
        }
      };
    },
    positionIconDownload() {
      return function(data) {
        if (data && data.loadingAnomalie) {
          return { 'margin-top': '-39px' };
        } else if (
          data &&
          !data.loadingAnomalie &&
          data.anomalies.length == 0
        ) {
          return { 'margin-top': '-38px' };
        } else if (data.anomalies.length && data.anomalies.length > 2) {
          return { 'margin-top': '-24px' };
        } else if (data.anomalies.length && data.anomalies.length == 1) {
          return { 'margin-top': '-40px' };
        } else if (data.anomalies.length && data.anomalies.length == 2) {
          return { 'margin-top': '-33px' };
        } else {
          return { 'margin-top': '-47px' };
        }
      };
    },
    computedgetListCommercialSedentaire() {
      if (this.getListCommercialSedentaire) {
        const list = this.getListCommercialSedentaire.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListCommercialTerrain() {
      if (this.getListCommercialTerrain) {
        const list = this.getListCommercialTerrain.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListPrevisiteur() {
      if (this.getListPrevisiteur) {
        const list = this.getListPrevisiteur.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListPoseur() {
      if (this.getListPoseur) {
        const list = this.getListPoseur.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetAllRegie() {
      if (this.getAllRegie) {
        const list = this.getAllRegie.map(item => ({
          full_name: item.name
        }));
        return list;
      }
      return [];
    },

    computedgetAllSousRegie() {
      if (this.getAllSousRegie) {
        const list = this.getAllSousRegie.map(item => ({
          full_name: item.name
        }));
        return list;
      }
      return [];
    },
    computedRows() {
      if (!this.getData) {
        return [];
      }
      if (this.type === 'valides') {
        return this.getData.filter(
          row => !row.anomalies || row.anomalies.length == 0
        );
      } else if (this.type === 'invalides') {
        return this.getData.filter(
          row => row.anomalies && row.anomalies.length > 0
        );
      }
      return this.getData;
    },
    computedgetAnomalieData() {
      if (this.getAnomalieData) {
        return this.getAnomalieData.map(anomalies => {
          return anomalies.pourcentage;
        });
      }
      return [];
    },
    computedgetAnomalieLabel() {
      if (this.getAnomalieData) {
        return this.getAnomalieData.map(anomalies => {
          return anomalies.name;
        });
      }
      return [];
    },
    computedgetValiditeData() {
      if (this.getValiditeData) {
        return this.getValiditeData.map(anomalies => {
          return anomalies.pourcentage;
        });
      }
      return [];
    },
    computedChengeTypeLegend() {
      return this.changeTypeLegende;
    },
    ComputedgetAllHeader() {
      return this.fields;
    },
    ComputedButtonValidation() {
      this.ChangeMessageValidation(null);
      if (this.getStatu !== 'complet') {
        return false;
      } else {
        if (this.getDossierDoublent.length == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    computedListWeekMin() {
      return this.ListWeekMin;
    },
    computedgetListCouleur() {
      return [
        { full_name: 'Bleu' },
        { full_name: 'Jaune' },
        { full_name: 'Violet' },
        { full_name: 'Rose' }
      ];
    },
    computedgetListMPR() {
      return [{ full_name: 'Non' }, { full_name: 'Oui' }];
    },
    computedTabsList() {
      if (this.getDossierDoublent.length != 0) {
        return this.TabsList;
      } else {
        return this.TabsList.filter(item => item.title != 'Doublons dossier');
      }
    },
    ComputedSemaineDoublent() {
      return this.semaine;
    },
    ComputedAnneeDoublent() {
      return this.year;
    },
    ComputedChangeDoublent() {
      return this.changeDoublent;
    },
    ComputedgetMissigSemaineRapport() {
      return this.getMissigSemaineRapport;
    }
  },
  async mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('Rapport-Filters')) {
      const localstorge = JSON.parse(localStorage.getItem('Rapport-Filters'));
      this.year = localstorge.year;
      this.semaine = localstorge.semaine;
      this.type = localstorge.type;
      this.anomalie = localstorge.anomalie;
      this.filterMonth = localstorge.month;
      this.switchMonth = localstorge.switchMonth;
      this.switchWeek = this.switchMonth == true ? false : true;
    } else {
      this.year = courantYear;
      const weekCourant = moment().isoWeek();
      this.semaine = {
        value: weekCourant,
        text: weekCourant
      };
      this.setLocalStorageRapport();
    }
    var totalWeek = moment({ year: this.year }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeekMin.push({ value: week, text: week });
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    if (sessionStorage.getItem('page-rapport')) {
      this.page = sessionStorage.getItem('page-rapport');
    } else {
      sessionStorage.setItem('page-rapport', this.page);
    }
    this.fetchEmployeParType();
    this.fetchAllRegieForAdmin();
    this.fetchAllSousRegieForAdmin();
    this.fetchAnalyseRapport();
    this.fetchSemaineValider({ year: this.year });
    this.FilterGetMissingSemainesRapport();
    this.changeDoublent = !this.changeDoublent;
    this.SET_LOADING_EDIT(false);
    this.getAllFiliales();
  },
  watch: {
    getStatusForRefrechData: function() {
      this.fetchProgressAnalyse({
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        type: this.type,
        anomalies: this.anomalie,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    getProgressValidation: function() {
      if (this.getProgressValidation.percent == 100) {
        this.fetchSemaineValider({ year: this.year });
      }
    }
  },
  components: {
    loader,
    EditableInput: () => import('./component/EditableInput.vue'),
    Charts: () => import('./component/Chart.vue'),
    SelectComponent: () => import('./SelectComponent.vue'),
    ProgressUpload: () => import('./component/progressUpload.vue'),
    SearchInput: () => import('./component/SearchInput.vue'),
    NumerouDoublent: () => import('./component/NumerouDoublent.vue')
  }
};
</script>

<style lang="scss" scoped>
#filiale-box-body::-webkit-scrollbar,
#filiale-body::-webkit-scrollbar {
  width: 7px;
}
#filiale-box-body::-webkit-scrollbar-track,
#filiale-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
#filiale-box-body::-webkit-scrollbar-thumb,
#filiale-body::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}

.content-tab-users {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .box-content {
    .reload {
      color: #2dabe2;
      padding: 3px;
      margin-right: 5px;
      font-size: 10px;
      position: relative;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: rgb(160 160 160);
      }
    }
    .validationMessage {
      color: #e24747;
      font-size: 12px;
      margin-left: 7px;
    }
    .chargement {
      margin-left: 10px;
      font-size: 8px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .body-box-rapport {
      .tabs-body-style {
        .tab-item-style {
          display: inline;
          .title-tab-item {
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            font-weight: 500;
            color: #5d5d5d;
          }
          .ligne {
            width: 100%;
            margin: auto;
            margin-top: 7px;
            margin-bottom: 7px;
          }
          .body-tab-item {
            .badge-style {
              margin-bottom: 5px;
              min-width: 51px;
              padding-top: 5px !important;
              padding-bottom: 5px !important;
              background-color: #6866b7 !important;
              color: #fff !important;
              border: 1px solid #6866b7;
              border-radius: 19px;
            }

            .filter-filiale {
              .filiale-header {
                width: 100%;
                height: 27px;
                background-color: #e0e0e0;
                margin: auto;
                border-radius: 10px;
                padding-top: 8px;
                .filiale-white-box {
                  background-color: white;
                  width: 92%;
                  height: 11px;
                  margin: auto;
                  border-radius: 20px;
                  box-shadow: 1px 2px #c3bbbb;
                }
              }
            }
            .filiale-icon-selected {
              width: 50px;
              height: 50px;
              margin-left: 31%;
              margin-top: -21px;
              position: absolute;
              overflow: hidden;
              background-size: contain;
              background-position: 50%;
              border-radius: 50px;
              box-shadow: 1px 2px 5px 1px #c4bbbb;
              background-repeat: no-repeat;
              cursor: pointer;
            }
            .filiale-body {
              padding: 10px;
              padding-top: 26px;
              overflow-y: auto;
              padding-bottom: 3px;
              overflow-x: hidden;
              justify-content: space-between;
              height: calc(100vh - 270px);
              margin-top: 21px;
              .filiale-box {
                background-color: #f5f4fa;
                margin-bottom: 28px;
                padding: 5px;
                min-width: 198px;
                height: 105px;
                display: inline;
                border-radius: 12px;
                font-size: 12px;
                display: grid;
                .filiale-box-icon {
                  width: 53px;
                  height: 53px;
                  margin-left: 35%;
                  margin-top: -28px;
                  overflow: hidden;
                  background-size: contain;
                  background-position: 50%;
                  border-radius: 50px;
                  box-shadow: 1px 2px 5px 1px #c4bbbb;
                  background-repeat: no-repeat;

                  cursor: pointer;
                  &:hover {
                    width: 55px;
                    height: 55px;
                    margin-top: -30px;
                    -ms-transform: scale(1.5); /* IE 9 */
                    -webkit-transform: scale(1.5); /* Safari 3-8 */
                    transform: scale(1.5);
                  }
                }
                .filiale-icon-downoad {
                  margin-left: 91%;
                  font-size: 14px;
                  color: #6c757d;
                  cursor: pointer;
                  &:hover {
                    color: #2dabe2;
                  }
                }
                .filiale-icon-downoad-loader {
                  margin-left: 91%;
                  font-size: 14px;
                  color: #2dabe2;
                  cursor: pointer;
                  &:hover {
                    color: #2dabe2;
                  }
                }
                .filiale-no-anomalie {
                  text-align: center;
                  font-family: 'Montserrat', sans-serif;
                  font-size: 11px;
                  color: #5d5d5d;
                }
                .disabledIcon {
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                }
                .filiale-box-body {
                  color: black;
                  overflow-y: auto;
                  display: inline;
                  padding-right: 7px;

                  .filiale-anomalie {
                    cursor: pointer;
                    justify-content: space-between;
                    display: flex;
                    &:hover {
                      color: #2dabe2;
                      text-decoration: underline;
                    }
                    .filiale-anomalie-title {
                      &:hover {
                        color: #2dabe2;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .display-tabs {
        display: none;
      }
      .table-rapport-style {
        .table {
          height: calc(100% - 50px);
        }
        .b-table-sticky-header {
          max-height: calc(100vh - 175px) !important;
          height: calc(100vh - 175px);
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
      }
      .width-table-rapport {
        width: 100%;
      }
    }
  }
  .surface-box {
    .title {
      color: #5d5d5d;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      margin-left: 19%;
      margin-bottom: 2px;
    }
    .valueBox {
      margin: auto;
      border-radius: 10px;
      background-color: #f5f5ff;
      width: 120px;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: #ff7f8b;
    }
  }
}
</style>
<style lang="scss">
.d-block {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.actionModel {
  text-align: center;

  .button-cancel-style {
    width: 100px;
  }
}
.swal2-actions {
  .swal2-confirm {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 9px;
    padding: 7px 15px;
    border: none;
    width: 68px;
    margin-right: 11px;
    outline: none;
  }
  .swal2-cancel {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 9px;
    padding: 8px 15px !important;
    border: none;
    height: 33px;
    outline: none;
  }
}
.thColorSearch {
  box-shadow: 0px 1px 1px #4d4bac33;
  background-color: #4d4bac12;
  padding: 2px;
  outline: none;
}
.desactive {
  color: #c5c5c5;
}
.active {
  color: #8d8cb7;
}
.classAlert,
.cardAnomalie {
  position: relative;
  background-color: #ededed !important;
  color: #858283;
  p {
    margin-bottom: 0px;
    font-size: 14px;
    width: 78px;
  }
}
.cardAnomalie {
  p {
    color: #fba5b3;
  }
}
.classAlert {
  p {
    color: #e8c559;
  }
}
.anomalie {
  width: 250px;
  text-align: left;
  padding-left: 28px;
  cursor: context-menu;
  b {
    &:hover {
      text-decoration: underline;
    }
  }
}
.custom-table-rapport-style {
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    font-size: 10px;
    padding: 0px 2px;
  }
}
</style>
